define("bottomline-execution/models/trip-stop", ["exports", "@ember/object/computed", "@ember/array", "@ember/object", "@ember/utils", "@ember/service", "ember-data", "moment", "rsvp", "bottomline-execution/models/base", "bottomline-execution/utils/find-or-initialize-trip-stop-truck-storage", "bottomline-execution/utils/sort-utils", "bottomline-execution/mixins/storages-loader", "bottomline-execution/mixins/offline-errors", "bottomline-execution/mixins/app-state", "bottomline-execution/mixins/adapter-options", "bottomline-execution/mixins/map-element-data", "bottomline-execution/validations/trip-stop-validations"], function (_exports, _computed, _array, _object, _utils, _service, _emberData, _moment, _rsvp, _base, _findOrInitializeTripStopTruckStorage, _sortUtils, _storagesLoader, _offlineErrors, _appState, _adapterOptions, _mapElementData, _tripStopValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint require-yield: "off" */

  /* eslint-env es6 */
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var _default = _base.default.extend(_appState.default, _adapterOptions.default, _mapElementData.default, _offlineErrors.default, _storagesLoader.default, _tripStopValidations.Validations, _tripStopValidations.ValidationCommonalities, {
    session: (0, _service.inject)(),
    internetConnection: (0, _service.inject)(),
    currentTruckInventory: (0, _service.inject)(),
    store: (0, _service.inject)(),
    reloaded: false,
    truckInventoriesSorted: ['TruckStorageID'],
    truckInventoriesSortedDesc: ['TruckStorageID:desc'],
    authorizerName: '',
    authorizerSurName: '',
    confirmedQty: attr('number'),
    forReport: attr('boolean'),
    SequenceNb: attr('number'),
    BarrelCount: attr('number'),
    plannedQty: attr('number'),
    ConfirmedSequenceNb: attr('number'),
    state: attr('string'),
    StorageGroupID: attr('string'),
    TripID: attr('string'),
    TruckID: attr('string'),
    ResourceID: attr('string'),
    longitude: attr('number'),
    latitude: attr('number'),
    sitename: attr('string'),
    ActivityCode: attr('string'),
    SiteID: attr('string'),
    DriverRemarks: attr('string'),
    Odometer: attr('number'),
    ExtOrderID: attr('string'),
    OrderID: attr('string'),
    OrderRef: attr('string', {
      defaultValue: ''
    }),
    callCustomer: attr('boolean'),
    ConfirmedArrivalDT: attr('string'),
    ConfirmedDepartureDT: attr('string'),
    PlannedArrivalDT: attr('string'),
    OrderSource: attr('string'),
    Remarks: attr('string'),
    DriverNotifiedCustomerOnArrivalYN: attr('boolean', {
      defaultValue: false
    }),
    DriverNotifiedCustomerOnDepartureYN: attr('boolean', {
      defaultValue: false
    }),
    site: belongsTo('site', {
      async: false,
      inverse: 'trip_stops'
    }),
    trip: belongsTo('trip', {
      async: false,
      inverse: 'trip_stops'
    }),
    order: belongsTo('order', {
      async: false
    }),
    pictures: hasMany('pictures', {
      async: false
    }),
    all_driver_remarks: hasMany('remark', {
      async: false
    }),
    trip_stop_storages: hasMany('trip-stop-storage', {
      async: false
    }),
    trip_stop_truck_storages: hasMany('trip-stop-truck-storage', {
      async: false
    }),
    trip_stop_remarks: (0, _computed.alias)('all_driver_remarks'),
    ownerRequiresPreAuthorizationForm: (0, _computed.alias)('site.customer.PreAuthorizationFormYN'),
    ownerRequiresPostAuthorizationForm: (0, _computed.alias)('site.customer.PostAuthorizationFormYN'),
    prepareForSerialization: function prepareForSerialization() {
      return this.get('order').prepareForSerialization();
    },
    isStale: function isStale(referenceTime) {
      return this.get('site').isStale(referenceTime);
    },
    ticketNbChanged: (0, _object.observer)('ticketNb', function () {
      if (!this.get('truckSettings.delivery_ticketnb_per_tank')) {
        this.get('trip_stop_storages').setEach('TicketNb', this.get('ticketNb'));
      }
    }),
    sortedAllStorages: (0, _computed.sort)('allStorages', 'truckInventoriesSorted'),
    sortedTruckStorages: (0, _computed.sort)('truckStorages', 'truckInventoriesSorted'),
    sortedTrailerStorages: (0, _computed.sort)('trailerStorages', 'truckInventoriesSorted'),
    sortedTruckStoragesDesc: (0, _computed.sort)('truckStorages', 'truckInventoriesSortedDesc'),
    sortedTrailerStoragesDesc: (0, _computed.sort)('trailerStorages', 'truckInventoriesSortedDesc'),
    truckInventories: (0, _object.computed)('truckSettings.truck_compartments_order_desc', function () {
      return this.get('truckSettings.truck_compartments_order_desc') ? this.get('sortedTruckStoragesDesc') : this.get('sortedTruckStorages');
    }),
    trailerInventories: (0, _object.computed)('truckSettings.truck_compartments_order_desc', function () {
      return this.get('truckSettings.truck_compartments_order_desc') ? this.get('sortedTrailerStoragesDesc') : this.get('sortedTrailerStorages');
    }),
    truckSettings: (0, _computed.alias)('appState.truckSettings'),
    meterTruckId: (0, _object.computed)('trip_stop_storages.@each', function () {
      var truckIds = this.get('trip_stop_storages').mapBy('truckId');
      return truckIds.find(function (truckId) {
        return truckId !== null;
      });
    }),
    canSave: (0, _object.computed)('trip_stop_storages.@each.confirmedQty', 'trip_stop_truck_storages.@each.confirmedQty', function () {
      if (this.get('truckSettings.compartments')) {
        return this.get('trip_stop_storages').isEvery('validVolume', true);
      }

      return true;
    }),
    canPreAuthorize: (0, _object.computed)('authorizerNamesAreValid', 'allStorages.@each.tankReferenceArray', function () {
      var anyTruckStorageHasTankReference = this.get('allStorages').any(function (truckStorage) {
        return (0, _utils.isPresent)(truckStorage.get('tankReferenceArray'));
      });
      return anyTruckStorageHasTankReference && this.get('authorizerNamesAreValid');
    }),
    canPostAuthorize: (0, _computed.alias)('authorizerNamesAreValid'),
    authorizerNameIsValid: (0, _object.computed)('authorizerName', function () {
      return this.get('authorizerName').replace(/\s+/g, '').length > 0;
    }),
    authorizerSurNameIsValid: (0, _object.computed)('authorizerSurName', function () {
      return this.get('authorizerSurName').replace(/\s+/g, '').length > 0;
    }),
    authorizerNamesAreValid: (0, _object.computed)('authorizerNameIsValid', 'authorizerSurNameIsValid', function () {
      return this.get('authorizerNameIsValid') && this.get('authorizerSurNameIsValid');
    }),
    authorizerDateTime: (0, _object.computed)(function () {
      return (0, _moment.default)();
    }),
    customerNb: (0, _object.computed)(function () {
      if ((0, _utils.isPresent)(this.get('OrderRef'))) {
        return this.get('OrderRef').split(';')[0];
      }
    }),
    tripStopStorages: (0, _object.computed)('trip_stop_storages.[]', function () {
      return this.get('trip_stop_storages');
    }),
    allStorages: (0, _object.computed)(function () {
      return this.get('truckStorages').concat(this.get('trailerStorages'));
    }),
    truckStorages: (0, _object.computed)(function () {
      return this.get('currentTruckInventory').forVehicle(this.get('appState.currentTruck'));
    }),
    trailerStorages: (0, _object.computed)(function () {
      var currentTrailer = this.get('appState').getTrailer();
      var trailerStorages = [];

      if ((0, _utils.isPresent)(currentTrailer)) {
        trailerStorages = this.get('currentTruckInventory').forVehicle(currentTrailer.get('id'));
      }

      return trailerStorages;
    }),
    ticketNb: (0, _object.computed)('trip_stop_storages.@each.TicketNb', function () {
      if (this.get('trip_stop_storages.firstObject')) {
        return this.get('trip_stop_storages.firstObject.TicketNb');
      }
    }),
    canStart: (0, _computed.equal)('state', 'planned'),
    canContinue: (0, _computed.equal)('state', 'started'),
    canExecute: (0, _computed.or)('canStart', 'canContinue'),
    driverActionName: (0, _object.computed)('state', function () {
      switch (this.get('state')) {
        case 'planned':
          return 'start';

        case 'started':
          return 'resume';

        default:
          return '?';
      }
    }),
    isEditable: (0, _object.computed)('state', 'trip.state', 'ActivityCode', function () {
      return this.get('trip.state') === 'started' && this.get('state') === 'finished' && ['U', 'L', 'E'].includes(this.get('ActivityCode'));
    }),
    variableExecutionOrder: (0, _object.computed)('isConfirmed', function () {
      return !this.get('isLoad') && !this.get('isConfirmed');
    }),
    showPlannedQtyInTripSummary: (0, _object.computed)(function () {
      return ['L', 'U'].includes(this.get('ActivityCode'));
    }),
    showConfirmedQtyInTripSummary: (0, _object.computed)('confirmedQty', function () {
      return ['L', 'U', 'E'].includes(this.get('ActivityCode')) || this.get('isCorrectionStop') && this.get('confirmedQty') !== 0;
    }),
    isConfirmed: (0, _computed.equal)('state', 'finished'),
    hasPictures: (0, _object.computed)('pictures.[]', function () {
      var _this = this;

      var pictures = this.get('store').peekAll('picture').filter(function (picture) {
        return picture.get('TableKey') == _this.get('id') && ['picture', 'scan', ''].includes(picture.get('PictureType'));
      });
      return pictures.length > 0;
    }),
    hasSeningMeter: (0, _object.computed)('trip_stop_storages.@each', 'trip_stop_storages.@each.meterType', function () {
      var meterTypes = this.get('trip_stop_storages').mapBy('meterType');
      return meterTypes.any(function (meterType) {
        return meterType === 'SENING';
      });
    }),
    remarks: (0, _computed.alias)('allRemarks'),
    allRemarks: (0, _object.computed)('site.site_remarks', 'order.order_remarks', 'trip_stop_remarks', function () {
      var siteRemarks = (this.get('site.site_remarks') || []).toArray();
      var orderRemarks = (this.get('order.order_remarks') || []).toArray();
      var tripStopRemarks = this.get('trip_stop_remarks').toArray();
      var aggregatedRemarks = [].concat(siteRemarks, orderRemarks, tripStopRemarks).mapBy('Remark').compact().map(function (remark) {
        return remark.trim();
      }).map(function (remark) {
        if (remark.slice(-1) == '.') return remark.slice(0, -1);
        return remark;
      }).filter(function (remark) {
        return (0, _utils.isPresent)(remark);
      }).uniq();
      return aggregatedRemarks;
    }),
    hasRemarks: (0, _computed.notEmpty)('allRemarks'),
    isExportedFromBS: (0, _object.computed)('ActivityCode', 'plannedQty', function () {
      return this.get('isUnload') && this.get('plannedQty') === 0;
    }),
    isCreatedFromOrder: (0, _computed.alias)('isExportedFromBS'),
    isManualOrder: (0, _computed.alias)('order.isManual'),
    orderQty: (0, _computed.alias)('order.OrderQty'),
    activity: (0, _computed.readOnly)('ActivityCode'),
    orderSourceIcon: (0, _object.computed)('OrderSource', function () {
      var activity = this.get('OrderSource');
      var icon = '';

      switch (activity) {
        case 'M':
          icon = 'assignment-ind';
          break;

        default:
          icon = '';
      }

      return icon;
    }),
    activityIcon: (0, _object.computed)('activity', function () {
      var activity = this.get('activity');
      var icons = {
        L: 'file-upload',
        U: 'file-download',
        N: 'flag',
        M: 'filter-none',
        P: 'local-parking'
      };
      return icons[activity] ? icons[activity] : 'pin-drop';
    }),
    hasDriverActions: (0, _object.computed)('ActivityCode', 'trip.allStopsUnloaded', function () {
      return ['L', 'U'].includes(this.get('ActivityCode')) || ['E'].includes(this.get('ActivityCode')) && this.get('trip.allStopsUnloaded');
    }),
    isLoad: (0, _computed.equal)('ActivityCode', 'L'),
    isEndInventory: (0, _computed.equal)('ActivityCode', 'E'),
    isUnload: (0, _computed.equal)('ActivityCode', 'U'),
    isStartInventory: (0, _computed.equal)('ActivityCode', 'S'),
    isMaterialTransfer: (0, _computed.equal)('ActivityCode', 'M'),
    isCorrectionStop: (0, _object.computed)(function () {
      return this.get('ActivityCode') == 'N' && this.get('SequenceNb') == 999;
    }),
    isParking: (0, _computed.equal)('ActivityCode', 'P'),
    started: (0, _computed.equal)('state', 'started'),
    shift: (0, _object.computed)(function () {
      if (this.get('trip')) {
        return this.get('trip').get('shift');
      }
    }),
    tripStopStorageWithPlanned: (0, _object.computed)('trip_stop_storages.[]', function () {
      return this.get('trip_stop_storages').filter(function (tss) {
        return tss.get('plannedQty') > 0;
      });
    }),
    tripStopStorageWithConfirmed: (0, _object.computed)('trip_stop_storages.[]', function () {
      return this.get('trip_stop_storages').filter(function (tss) {
        return tss.get('confirmedQty') > 0;
      });
    }),
    tripStopStorageByTruckInventory: (0, _object.computed)('trip_stop_storages.[]', function () {
      var truckMaterials = this.get('currentTruckInventory').materialIds();
      return this.get('trip_stop_storages').filter(function (tss) {
        return truckMaterials.includes(tss.get('MaterialID'));
      });
    }),
    compartments: (0, _object.computed)('trip_stop_truck_storages', function () {
      return this.get('trip_stop_truck_storages').filterBy('isCompartmentRecord');
    }),
    tstsCompartments: (0, _object.computed)('allStorages.[]', function () {
      return (0, _sortUtils.sortCollection)(this.get('allStorages'), 'TruckStorageID');
    }),
    tstsStorages: (0, _computed.sort)('compartments', 'truckInventoriesSorted'),
    truckInventory: (0, _object.computed)(function () {
      return this.get('trip.shift.truck_inventories');
    }),
    plannedTripStopStorages: (0, _object.computed)('trip_stop_storages.@each.plannedQty', function () {
      return this.get('trip_stop_storages').filter(function (tss) {
        return tss.get('plannedQty') > 0;
      });
    }),
    confirmedTripStopStorages: (0, _object.computed)('trip_stop_storages.@each.confirmedQty', function () {
      return this.get('trip_stop_storages').filter(function (tss) {
        return tss.get('confirmedQty') > 0;
      });
    }),
    plannedMaterials: (0, _object.computed)(function () {
      console.error('TripStop.plannedMaterials CP should be implemented in the BE- and BX-specific models');
    }),

    get deliveredMaterials() {
      var _this2 = this;

      var result = (0, _array.A)([]); // Build result array for post authorize form

      this.get('trip_stop_truck_storages').filterBy('isCompartmentRecord').map(function (tsts) {
        if (tsts.get('confirmedQty') > 0) {
          var record = {};
          record['truckStorageId'] = tsts.get('TruckStorageID');
          record['material'] = tsts.get('material') || _this2.loadData(tsts.get('TruckStorageID')).material;
          record['plannedQty'] = tsts.get('plannedQty');
          record['confirmedQty'] = tsts.get('confirmedQty');
          record['Loaded'] = _this2.loadData(tsts.get('TruckStorageID')).confirmedQty;
          result.pushObject(record);
        }
      });
      return result.sort(function (a, b) {
        return (0, _sortUtils.alphaNumericSort)(a.truckStorageId, b.truckStorageId);
      });
    },

    tanksNotOnSite: function tanksNotOnSite() {
      var tripStopStorages = this.get('trip_stop_storages');
      var tripStopLatitude = this.get('latitude');
      var tripStopLongitude = this.get('longitude');
      return tripStopStorages.filter(function (tank) {
        var tankLatitude = tank.get('storage.latitude');
        var tankLongitude = tank.get('storage.longitude');
        if ((0, _utils.isEmpty)(tankLatitude) || (0, _utils.isEmpty)(tankLongitude)) return false;
        return tripStopLatitude !== tankLatitude || tripStopLongitude !== tankLongitude;
      }, []);
    },
    isPreAuthorizationRequired: (0, _object.computed)('truckSettings.pre_authorize_unload', 'isUnload', 'ownerRequiresPreAuthorizationForm', function () {
      if (!this.get('isUnload')) return false;
      if (this.get('truckSettings.pre_authorize_unload') === 'by_owner') return this.get('ownerRequiresPreAuthorizationForm');
      return this.get('truckSettings.pre_authorize_unload');
    }),
    isPostAuthorizationRequired: (0, _object.computed)('truckSettings.post_authorize_unload', 'isUnload', 'ownerRequiresPostAuthorizationForm', function () {
      if (!this.get('isUnload')) return false;
      if (this.get('truckSettings.post_authorize_unload') === 'by_owner') return this.get('ownerRequiresPostAuthorizationForm');
      return this.get('truckSettings.post_authorize_unload');
    }),
    loadData: function loadData(truckStorageID) {
      var trip = this.get('trip');
      var result = {};
      result['confirmedQty'] = 0;
      result['confirmed15Qty'] = 0;
      result['Temperature'] = 0;
      result['count'] = 0;
      trip.get('tripStops').filterBy('ActivityCode', 'L').forEach(function (ts) {
        ts.get('trip_stop_truck_storages').filterBy('TruckStorageID', truckStorageID).forEach(function (tsts) {
          result['confirmedQty'] += tsts.get('confirmedQty');
          result['confirmedQty15'] += tsts.get('confirmed15Qty');
          result['Temperature'] += tsts.get('Temperature');
          result['materialId'] = tsts.get('MaterialID');
          result['material'] = tsts.get('material');
          result['materialNameShort'] = tsts.get('material.MaterialNameShort');
          result['count'] += 1;
        });
      });

      if (result['count'] !== 0) {
        result['Temperature'] /= result['count'];
      }

      return result;
    },
    setConfirmedSequenceNb: function setConfirmedSequenceNb() {
      this.set('ConfirmedSequenceNb', this.get('trip').getMaxTripStopSequenceNb() + 1);
    },
    start: function start() {
      var _this3 = this;

      if (!this.get('ConfirmedArrivalDT')) {
        this.markAsStarted(new Date());
      }

      if (this.get('isEndInventory')) {
        // Make correction stop ConfirmedArrivalDT equal to that of the end inventory stop (EP-20)
        this._alignCorrectionStopProperties();
      }

      if ((0, _utils.isEmpty)(this.get('ConfirmedSequenceNb'))) {
        this.setConfirmedSequenceNb();
      }

      if (this.get('truckSettings.compartments')) {
        this.get('truckStorages').forEach(function (truckStorage) {
          var truckStorageID = truckStorage.get('TruckStorageID');

          var truckInventory = _this3.get('currentTruckInventory').withTruckStorageId(truckStorageID);

          var storages = _this3.get('site.storages');

          if (storages) {
            (0, _findOrInitializeTripStopTruckStorage.findOrInitializeTripStopTruckStorage)(_this3, storages, truckStorageID, truckInventory);
          }
        });
      } else {
        this.get('trip').calculateTruckInventoryPerMaterial();
      }
    },
    markAsStarted: function markAsStarted(confirmedArrivalDT) {
      this.setTimeIfEmpty('ConfirmedArrivalDT', confirmedArrivalDT);
    },
    finish: function finish() {
      var confirmedDepartureDT = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : new Date();
      this.setTimeIfEmpty('ConfirmedDepartureDT', confirmedDepartureDT);
      this.set('state', 'finished');
    },
    confirm: function confirm() {
      var _this4 = this;

      if (this.get('isLoad') && this.get('truckSettings.compartments')) {
        var loadStopTruckStorages = this.get('trip_stop_truck_storages');
        this.get('currentTruckInventory').updateMaterialsIds(loadStopTruckStorages);
      }

      if (this.get('isEndInventory')) {
        this.get('trip').finishNonVisibleUnstartableTripStops();

        this._alignCorrectionStopProperties();

        var correctionStop = this.get('trip').correctionStop();
        return correctionStop.confirm().then(function () {
          return _this4._confirmTripStop();
        });
      } else {
        return this._confirmTripStop();
      }
    },
    cancel: function cancel() {
      this.rollbackAttributes();

      this._rollbackTripStopStorages();

      this._rollbackTripStopTruckStorages();

      this.get('currentTruckInventory').rollbackAttributes();

      if (this.get('isEndInventory')) {
        this.get('trip').findCorrectionStop().cancel();
      }
    },
    _alignCorrectionStopProperties: function _alignCorrectionStopProperties() {
      var correctionStop = this.get('trip').correctionStop();
      correctionStop.markAsStarted(this.get('confirmedArrivalDT'));
      correctionStop.set('Odometer', this.get('Odometer'));
      correctionStop.set('ConfirmedSequenceNb', correctionStop.get('SequenceNb'));
    },
    _rollbackTripStopStorages: function _rollbackTripStopStorages() {
      var tripStopStorages = this.get('trip_stop_storages').toArray();
      tripStopStorages.invoke('rollbackAttributes');
      tripStopStorages.invoke('resetProcessDeliveryFlag');
    },
    _rollbackTripStopTruckStorages: function _rollbackTripStopTruckStorages() {
      var tripStopTruckStorages = this.get('trip_stop_truck_storages').toArray();
      tripStopTruckStorages.filterBy('isNew').invoke('deleteRecord');
      tripStopTruckStorages.invoke('rollbackAttributes');
    },
    totalPlannedQty: function totalPlannedQty(materialID) {
      var compatibleTripStopStorages = this.getCompatibleTripStopStorages(materialID);
      var totalPlannedQty = compatibleTripStopStorages.reduce(function (sum, tss) {
        return sum + parseInt(tss.get('plannedQty'));
      }, 0);
      return totalPlannedQty;
    },
    tripStopTruckStorageTotalByMaterial: function tripStopTruckStorageTotalByMaterial(materialID, targetQuantity) {
      var tripStopTruckStorages = this.get('trip_stop_truck_storages').filterBy('isCompartmentRecord').filterBy('MaterialID', materialID);
      return tripStopTruckStorages.reduce(function (sum, tsts) {
        return sum + Number(tsts.get(targetQuantity));
      }, 0);
    },
    tripStopTruckStoragesForStorage: function tripStopTruckStoragesForStorage(storageID) {
      return this.get('trip_stop_truck_storages').filterBy('StorageID', storageID);
    },
    getTripStopStorage: function getTripStopStorage(materialId) {
      return this.get('trip_stop_storages').findBy('MaterialID', materialId);
    },
    getCompatibleTripStopStorages: function getCompatibleTripStopStorages(materialId) {
      var targetMaterial = this.get('store').peekRecord('material', materialId);
      var compatibleMaterialIds = targetMaterial.get('compatibleMaterials').map(function (m) {
        return m.get('id');
      });
      var materialIds = [materialId, compatibleMaterialIds].flat().uniq();
      return this.get('trip_stop_storages').filter(function (tss) {
        return materialIds.includes(tss.get('MaterialID'));
      });
    },
    getConfirmedQty: function getConfirmedQty(materialId) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
        allUnits: false
      };
      var qty = 0;
      var qty15 = 0;
      this.getCompatibleTripStopStorages(materialId).forEach(function (tss) {
        if (tss.get('confirmedQty')) {
          qty += parseInt(tss.get('confirmedQty'), 10);
        }

        if (tss.get('confirmed15Qty')) {
          qty15 += parseInt(tss.get('confirmed15Qty'), 10);
        }
      });

      if (options.allUnits) {
        return {
          qty: qty,
          qty15: qty15
        };
      } else {
        return this.get('unitOfCalculationQty') == 'confirmedQty' ? qty : qty15;
      }
    },
    getExpectedQty: function getExpectedQty(materialId) {
      var _this5 = this;

      var qty = 0;
      var qty15 = 0;
      this.getCompatibleTripStopStorages(materialId).forEach(function (tss) {
        if (_this5.get('isConfirmed')) {
          qty += Number(tss.get('confirmedQty')) || 0;
          qty15 += Number(tss.get('confirmed15Qty')) || 0;
        } else if (tss.get('plannedQty') != null) {
          qty += Number(tss.get('plannedQty'));
          qty15 += Number(tss.get('plannedQty'));
        }
      });
      return {
        qty: qty,
        qty15: qty15
      };
    },
    registerQty: function registerQty(materialId, confirmedQty, confirmed15Qty) {
      var tripStopStorage = this.getTripStopStorage(materialId);
      var tripID = this.get('TripID');

      if (!tripStopStorage) {
        tripStopStorage = this.get('trip_stop_storages').createRecord({
          id: this.createGuid(),
          TripStopID: this.get('id'),
          TripID: tripID,
          plannedQty: 0,
          state: 'finished'
        });
      }

      if (this.get('truckSettings.disable_standard_liters_aggregation')) {
        confirmed15Qty = null;
      }

      tripStopStorage.setProperties({
        confirmedQty: confirmedQty,
        confirmed15Qty: confirmed15Qty,
        TripID: tripID,
        MaterialID: materialId
      });
      return tripStopStorage;
    },
    keepOnlyForMaterials: function keepOnlyForMaterials(materialIds) {
      var orphanTripStopStorages = this.get('trip_stop_storages').reject(function (tss) {
        return materialIds.includes(tss.get('MaterialID')) || tss.get('confirmedQty') != null || tss.get('plannedQty');
      });
      orphanTripStopStorages.forEach(function (tss) {
        return tss.deleteRecord();
      });
    },
    totalize: function totalize() {
      var plannedQty = 0;
      var confirmedQty = 0;
      var targetQuantity = this.get('unitOfCalculationQty');
      this.get('trip_stop_storages').forEach(function (tss) {
        plannedQty += tss.get('plannedQty');
        confirmedQty += parseInt(tss.get(targetQuantity), 10) || 0;
      });
      this.setProperties({
        plannedQty: plannedQty,
        confirmedQty: confirmedQty
      });
    },
    _updateStatus: function _updateStatus() {
      this.finish();

      if (this.get('isEndInventory')) {
        var trip = this.get('trip'); // Make correction stop ConfirmedDepartureDT equal to that of the end inventory stop (EP-393)

        var confirmedDepartureDT = this.get('ConfirmedDepartureDT');
        trip.correctionStop().finish(confirmedDepartureDT);
      }
    },
    _totalizeTripStopStorages: function _totalizeTripStopStorages() {
      this.totalize();
    },
    _calculateTrip: function _calculateTrip() {
      var trip = this.get('trip');
      trip.calculateEndInventoryAndCorrection();
    },
    _calculateTruckInventory: function _calculateTruckInventory() {
      var trip = this.get('trip');
      trip.calculateTruckInventoryPerMaterial();

      if (this.get('truckSettings.compartments')) {
        trip.calculateTruckInventoryPerCompartment();
      }

      this.get('currentTruckInventory').confirm();
    },
    _persistChanges: function _persistChanges() {
      var _this6 = this;

      console.info("Start saving trip stop.");
      var trip = this.get('trip');
      return trip.save({
        trigger: 'save-trip-stop'
      }).then(function () {
        console.log('Retrieving trip stop from backend', _this6.id);

        if (_this6.get('isEndInventory')) {
          var correctionStop = _this6.get('trip').correctionStop();

          correctionStop.reload();
        }

        if (!_this6.get('isDeleted')) {
          return _this6.reload();
        }
      }).catch(function (error) {
        return _this6._handleSaveError(error, 'Trip');
      });
    },
    _confirmTripStop: function _confirmTripStop() {
      if (!this.get('isCorrectionStop')) {
        /*
          * The end inventory stop will trigger status update for the correction stop
          * to ensure the ConfirmedDepartureDT is equal to that of the end inventory stop
          */
        try {
          this._updateStatus();
        } catch (error) {
          return _rsvp.Promise.reject(error);
        }
      }

      try {
        this._calculateTrip();

        this._totalizeTripStopStorages();

        this._calculateTruckInventory();
      } catch (error) {
        return _rsvp.Promise.reject(error);
      }

      if (!this.get('isCorrectionStop')) {
        /*
         * The changes to the correction stop are triggered and persisted by the
         * end inventory stop.
         */
        return this._persistChanges();
      }

      return _rsvp.Promise.resolve();
    },
    _markTripStopAsSaved: function _markTripStopAsSaved() {
      var noAction = this.adapterAction('no-op');
      this.save(noAction); // Ember does not handle persisted models without ID well

      this._ensureModelHasId();

      if (this.get('isEndInventory')) {
        var correctionStop = this.get('trip').correctionStop();
        correctionStop.save(noAction);
      }

      this._saveStorages('trip_stop_storages');

      this._saveStorages('trip_stop_truck_storages');
    },
    _ensureModelHasId: function _ensureModelHasId() {
      if ((0, _utils.isBlank)(this.get('id'))) {
        this.set('id', this.createGuid());
      }
    },
    _saveStorages: function _saveStorages(storageType) {
      var storages = this.get(storageType).toArray();
      storages.invoke('save');
    },
    _loadStoragesForNewSite: function _loadStoragesForNewSite(isNewTripStop) {
      var siteId = this.get('SiteID');

      if (isNewTripStop && (0, _utils.isPresent)(siteId)) {
        return this.loadSiteStorages([siteId]);
      } else {
        return _rsvp.Promise.resolve();
      }
    },
    save: function save() {
      var _this7 = this;

      var isNewTripStop = this.get('isNew');
      return this._super.apply(this, arguments).then(function (tripStop) {
        return _this7._loadStoragesForNewSite(isNewTripStop).then(function () {
          return tripStop;
        });
      }).catch(function (error) {
        return _this7._handleSaveError(error, 'Trip stop');
      });
    },
    _handleSaveError: function _handleSaveError(error, modelType) {
      if (this.isOfflineError(error)) {
        console.log("%c".concat(modelType, " cached for later synchronization (").concat(error, ")"), 'color:red');

        this._markTripStopAsSaved();
      } else {
        throw error;
      }
    }
  });

  _exports.default = _default;
});